/* YtContainer.css */

.parent-yt-container {
  background-color: #e7e6dd;
  border-radius: 8px;
}

/* Title Styling */
.gallery-title {
  font-weight: bold;
  color: #333;
}

/* Video Card Styling */
.video-gallery-card {
  border: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: #fff;
}

.video-gallery-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Video Thumbnail with Rounded Borders */
.video-thumbnail {
  height: 300px;
  object-fit: cover;
  border-radius: 10px; /* Adjust the value for desired roundness */
}

/* The "View More" button remains unchanged */
