/* Define CSS variables for modern design */
:root {
  --primary-color: #007bff;
  --background-color: #f8f9fa;
  --text-color: #1c1c1c;
  --dropdown-bg: #ffffff;
  --highlight-color: gold;
}

.sidebar-managed {
  display: none;
}

/* Base styles */
.top-container {
  height: 80px;
  width: 100%;
  background-color: var(--background-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  z-index: 1000;
  text-align: left;
}

.top-container img {
  max-width: 130px;
}

.top-container:focus {
  outline: none;
  box-shadow: none;
}

.side-bar-logo {
  display: none;
}

.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

.navbar-nav .nav-item a {
  font-size: 1.1rem;
  color: var(--text-color);
}

.dropdown-item {
  background-color: transparent !important;
}

.dropdown-item:active,
.dropdown-item:focus {
  background-color: #fff;
  outline: none;
}

.nav-link.active {
  background-color: transparent !important;
  color: inherit;
}

/* Navbar Toggler */
.navbar-toggler {
  outline: none;
  border: none;
  box-shadow: none;
  font-size: 2em;
}

/* Dropdown Menu */
.dropdown-menu {
  background-color: var(--dropdown-bg);
  border: none;
  display: none;
  opacity: 1;
  visibility: visible;
}

.dropdown-menu.show {
  display: block !important;
  opacity: 1;
  visibility: visible;
}

.dropdown-menu li {
  padding: 10px 20px;
  display: block;
}

/* Social Icons */
.social-icons {
  display: flex;
  align-items: center;
}

.social-icons i {
  font-size: 1.3rem;
  color: #474747;
  margin-left: 15px;
}

.social-icons span a {
  background-color: var(--highlight-color);
  padding: 15px 15px;
  white-space: nowrap;
  border-radius: 6px;
  text-decoration: none;
  color: #000;
}

.social-icons span a:hover {
  background-color: #fff;
  color: #000;
  border: 3px solid var(--highlight-color);
}

.social-icons i:hover {
  color: var(--primary-color);
}

/* Mobile and Responsive Styles */
@media (max-width: 1050px) {
  .nav-item a {
    color: red;
  }
}

@media (max-width: 1024px) {
  .social-icons span a {
    background-color: var(--highlight-color);
    padding: 15px 10px;
  }
  .social-icons i {
    margin-left: 0;
  }
}

/* Mobile view styles */
@media (max-width: 991px) {
  .top-container img {
    max-width: 110px;
  }

  .managed-logo {
    height: 40px;
    width: 40px;
  }

  /* Sidebar styles */
  .navbar-collapse {
    position: fixed;
    top: 0;
    left: 0;
    width: 320px;
    height: 100vh;
    background-color: var(--dropdown-bg);
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    z-index: 2000;
    color: var(--text-color);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
  }

  .navbar-collapse.show {
    transform: translateX(0);
  }

  /* Sidebar wrapper: flex column to allow .sidebar-managed to stick to bottom */
  .sidebar-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .side-bar-logo {
    display: block;
    margin: 20px auto;
    max-width: 150px;
  }

  /* Navigation links left aligned in sidebar */
  .navbar-nav .nav-link {
    color: var(--text-color) !important;
    text-align: left; /* changed from center */
    width: 100%;
    padding: 10px 20px; /* added left/right padding */
  }

  .dropdown-menu {
    display: none;
  }

  .dropdown-menu.show {
    display: block;
  }

  .dropdown-menu li a {
    white-space: normal;
  }

  .nav-item.dropdown .dropdown-toggle {
    display: inline-block;
  }

  /* Hide default social icons in mobile */
  .social-icons {
    display: none;
  }

  /* Sidebar additional icons section */
  .sidebar-icons {
    display: block;
    margin-top: 20px;
    text-align: center;
  }

  .sidebar-icons .member-login span a {
    background-color: var(--highlight-color);
    padding: 15px 15px;
    white-space: nowrap;
    border-radius: 6px;
    text-decoration: none;
    color: #000;
  }

  .sidebar-icons .icons-div {
    margin-top: 30px;
    display: flex;
    gap: 20px;
    justify-content: center;
  }

  .sidebar-icons .icons-div a {
    color: #333;
    font-size: 1.5em;
  }

  .overlay {
    display: none;
  }

  .overlay.active {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1049;
  }

  /* Sidebar managed section at bottom (stays centered) */
  .sidebar-managed {
    margin-top: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
  }
}
