.form-parent{
    padding-top: 130px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 60px;
}

.form-child{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}



/* iframe{
    width: 100%;
    max-width: 800px;
    height: 1000px;
} */

/* Modal CSS */
.custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.custom-modal {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.3);
    text-align: center;
}



.custom-blue{
    background-color: #20b4e2;
}