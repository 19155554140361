.gallery-container {
    background-color: #e7e6dd;
    padding: 20px;
}

.gallery-content h4 {
    font-weight: bold;
}

/* Container for images with fixed width and height */
.img-wrapper {
    width: 300px; /* Set width to 250px */
    height: 200px; /* Fixed height for consistency */
    overflow: hidden; /* Hide overflow from object-fit */
    border-radius: 5px;
}

/* Make the image fill the container while preserving its aspect ratio */
.img-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the container and crops if needed */
}

/* Responsive adjustments for mobile devices */
@media (max-width: 768px) {
    .img-wrapper {
        width: 100%; /* Full width on mobile */
        height: 150px; /* Smaller fixed height for mobile view */
    }
}
