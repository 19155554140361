

.zone-container{
margin-top: -40px;
}


/* .pimg{
    display: flex;
    justify-content: center;
} */

.img-fluid{

    width: 400px;
    object-position: top;
}