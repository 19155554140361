/* Container padding */
.gallery-parent {
    padding: 2rem 0;
    margin-top: 80px;
}

.gallery-child h2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

/*
  1) Thumbnails: fixed height for a uniform grid
  2) Use object-fit: cover to maintain aspect ratio
*/
.gallery-thumbnail {
    width: 100%;
    height: 200px; /* Adjust to your preference */
    object-fit: cover;
    cursor: pointer;
    border-radius: 4px;
}

/* Lightbox overlay covers the entire screen */
.lightbox-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 9998;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Lightbox content container */
.lightbox-content {
    position: relative;
    background: transparent;
    padding: 1rem;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Close button in top-right corner */
.lightbox-close {
    position: absolute;
    top: 15px;
    right: 15px;
    background: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
}

/* Prev/Next arrow buttons */
.lightbox-prev,
.lightbox-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    border: none;
    color: #fff;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 50%;
}

.lightbox-prev {
    left: 15px;
}

.lightbox-next {
    right: 15px;
}

/* Make the image bigger on desktop, but still responsive on mobile */
.lightbox-img {
    max-width: 90vw;
    max-height: 90vh;
    border-radius: 4px;
}

/* YouTube iframe: fill up to 90vw/90vh */
.lightbox-iframe {
    width: 90vw;
    height: 90vh;
    border: none;
    border-radius: 4px;
}

/* Larger image for desktop */
@media (min-width: 992px) {
    .lightbox-img {
        max-width: 80vw;
        max-height: 80vh;
    }
    .lightbox-iframe {
        width: 80vw;
        height: 80vh;
    }
}

/* Spacing in the grid
   "col-6 col-sm-6 col-md-3 p-2" already ensures a small padding
   around each item. Adjust .p-2 or override if you want a bigger gap.
*/
